import axios from 'axios';
import { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router';


axios.defaults.baseURL = process.env.REACT_APP_BACKEND || "http://localhost:8000";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div
      className="pt-0 min-h-screen flex flex-col font-sans"
      style={{
        background: "rgb(238,255,239) linear-gradient(127deg, rgb(208,225,209) 0%, rgb(3,89,150) 100%)"
      }}>
      <ScrollToTop />
      <div className="flex-grow text-gray-800 flex-col font-sans">
        <div className="bg-opacity-50 bg-white max-w-screen-lg sm-auto mx-auto p-4 m-4 rounded">
          <Outlet />
        </div>
      </div>
    </div >
  );
}

export default App;
