import { Chart, registerables } from 'chart.js';
import axios from "axios";
import { useEffect, useState } from "react";
import { Scatter } from "react-chartjs-2";
import { useParams } from "react-router";

Chart.register(...registerables);

interface propData {
  data: Array<DataPoint>
}
const DataGraph = ({ data }: propData) => {
  if (!data || data.length < 1) {
    return <div>…</div>;
  }

  const tension = 0.1;
  let datasets = [{
    label: "Water Level (cm)",
    data: data.map((dp) => ({ x: new Date(dp.taken_at), y: dp.water_level })),
    borderColor: "cornflowerblue",
    backgroundColor: "cornflowerblue",
    tension: tension,
    showLine: true,
    fill: false,
  }, {
    label: "Battery Level (volts)",
    data: data.map((dp) => ({ x: new Date(dp.taken_at), y: dp.battery_voltage })),
    borderColor: "coral",
    backgroundColor: "coral",
    tension: tension,
    showLine: true,
    hidden: true,
  }];

  const graphData = {
    datasets: datasets,
  };

  const options = {
    scales: {
      y: {
        min: 0,
      },
      x: {
        ticks: {
          callback: (value: any) => new Date(value).toLocaleString().replace(", ", "\r")
        }
      }
    },
  };

  return <Scatter data={graphData} options={options} fallbackContent={<div>☠</div>} />;
}

const DataTable = ({ data }: propData) => {
  const temps = data;
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th>Time</th>
          <th>Water Level (cm)</th>
          <th>Battery Voltage (v)</th>
          <th>Charging?</th>
        </tr>
      </thead>
      <tbody className="text-center">
        {temps.map((temp, idx) => (
          <tr key={idx}>
            <td>{new Date(temp.taken_at).toLocaleString()}</td>
            <td>{temp.water_level}</td>
            <td>{temp.battery_voltage}</td>
            <td>{temp.battery_charging ? "☑" : "✖"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const Monitor = () => {
  const [data, setData] = useState<Array<DataPoint>>([]);
  const [loading, setLoading] = useState(true);
  const { serial } = useParams();

  useEffect(() => {
    axios
      .get(`/monitor/${serial}/all?days=28`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err?.response?.data?.detail))
      .finally(() => setLoading(false));
  });

  return (
    <div>
      <h1 className="font-semibold text-3xl font-mono">WiWLM {serial} Data</h1>
      {loading ? <p>Loading...</p> : (
        data.length > 0 ?
          (
            <>
              <DataGraph data={data} />
              <DataTable data={data} />
            </>
          ) : <h3>No measurements found for this monitor. Activate the device and come back later!</h3>
      )}
    </div>
  );
}

export default Monitor;
